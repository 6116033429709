<template>
  <b-form ref="passwordForm" @submit="onSubmit">
    <div class="app animated fadeIn">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card no-body>
            <b-card-header>
              <i class="icon-note"></i> Alterar Senha
            </b-card-header>
            <b-card-body>
              <cite
                >A senha deve ter ao menos 8 characteres, pelo menos uma letra
                maiúscula, uma letra minúscula e um número.</cite
              >
              <hr />
              <b-row>
                <b-col>
                  <b-form-group label="Nome de Usuário" label-for="userName">
                    <b-form-input
                      type="text"
                      v-model="form_data.userName"
                      aria-describedby="input1LiveFeedback1"
                      placeholder="First Name"
                      readonly
                    />
                  </b-form-group>

                  <b-form-group
                    id="passInputGroup5"
                    label="Senha Antiga"
                    label-for="oldPassword"
                  >
                    <b-form-input
                      id="oldPassword"
                      type="password"
                      v-model.trim="form_data.oldPassword"
                      placeholder="Senha Antiga"
                      required
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback5">
                      Required password containing at least: number, uppercase
                      and lowercase letter, 8 characters
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="passInputGroup5"
                    label="Nova Senha"
                    label-for="password"
                  >
                    <b-form-input
                      id="password"
                      type="password"
                      v-model.trim="form_data.password"
                      aria-describedby="input1LiveFeedback5"
                      placeholder="Nova Senha"
                      autocomplete="new-password"
                      required
                      :state="areEqual"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback5">
                      A senha deve ter ao menos 8 characteres, pelo menos uma
                      letra maiúscula, uma letra minúscula e um número.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="confPassInputGroup6"
                    label="Confirme a Nova Senha"
                    label-for="confirm_password"
                  >
                    <b-form-input
                      id="confirm_password"
                      type="password"
                      v-model.trim="form_data.confirmPassword"
                      aria-describedby="input1LiveFeedback6"
                      placeholder="Confirme a Nova Senha"
                      autocomplete="new-password"
                      required
                      :state="areEqual"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback6">
                      As senhas devem ser iguais.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-button
                class="float-right"
                :disabled="!isFormValid"
                type="submit"
                variant="primary"
              >
                Salvar
              </b-button>
              <b-button
                v-on:click="cancel()"
                class="float-right mr-1"
                type="button"
                variant="danger"
                >Cancelar</b-button
              >
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-form>
</template>

<script>
import Usuario from "../../../services/usuario";

export default {
  name: "AlterarSenha",
  data() {
    return {
      form_data: {
        userName: this.$store.state.login.user.username,
        confirmPassword: "",
        password: "",
        oldPassword: ""
      },
      dismissSecs: 5,
      dismissCountDown: 0
    };
  },
  computed: {
    areEqual() {
      return this.form_data.password == this.form_data.confirmPassword;
    },
    isFormValid() {
      return (
        this.form_data.oldPassword != "" &&
        this.form_data.password != "" &&
        this.form_data.password == this.form_data.confirmPassword
      );
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      Usuario.trocarSenha(
        this.form_data.userName,
        this.form_data.oldPassword,
        this.form_data.password
      )
        .then(response => {
          this.clearForm();
          this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch(e => {
          this.clearForm();
          this.$helper.showErrorResponse(e);
        });
    },
    cancel() {
      this.$router.push("/");
    },
    clearForm() {
      this.form_data.confirmPassword = "";
      this.form_data.password = "";
      this.form_data.oldPassword = "";
    }
  }
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
